<template>
  <div>
    <!-- <v-col class="col-12">
      <div class="text-lg-h5 text-sm-h4">
        {{ products ? products.length : 0 }} Products
      </div>
    </v-col> -->
    <v-layout wrap>
      <v-flex class="col-12 d-flex justify-space-between">
        <div class="text-lg-h6 text-sm-h5">
          {{ products.length ? products.length:0 }} Products
        </div>
        <div>
          <v-text-field
            v-model.lazy="keyword"
            @input="filterProduct"
            label="FIlter Products"
            outlined
          ></v-text-field>
        </div>
      </v-flex>
      <v-flex lg2 sm4 xs6 v-for="(item, i) in products" :key="item.id">
        <ProductSlot
          :img="item.photos"
          :name="item.name"
          :main_sku="item.main_sku"
          :price="item.price"
          :sizes="item.sku_size_stock_list"
          :class="
            $vuetify.breakpoint.smAndDown
              ? []
              : [(i + 1) % 3 == 0 ? 'pr-0' : 'mr-n5']
          "
          @onSeeDetail="seeDetail(item.id)"
          @onAddToCart="(sku) => quickAdd(item.id, item.main_sku, sku)"
        ></ProductSlot>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Service from '../../services'
import ProductSlot from '../ProductSlot'
export default {
  name: 'ProductListPanel',
  data(){
    return{
      keyword:'',
      brodcast:null
    }
  },
  components: {
    ProductSlot
  },
  props: {
    liveSource: {
      type: Object
    }
  },
  computed: {
    ...mapState({
      products(state) {
        return state.Live.productList.filter(
          (x) =>
            x.is_show === 1 &&
            x.facebook_live_id === this.liveSource.id &&
            x.is_facebook_live === 1
        )
      }
    })
  },
  methods: {
    seeDetail(id) {
      this.$router.push({ name: 'ProductDetail', params: { id: id } })
    },
    filterProduct(){
      console.log(this.keyword)
      this.$store.dispatch('Live/filterProductLive',this.keyword)
    }
  },
  async created() {
    this.brodcast = await Service.broadcast.initBroadcast()
    const client = this.brodcast.subscribe('product_ch')
    client.bind('product_resp', (data) => {
      this.$store.commit('Live/editProductList', data.product[0])
    })
  },
  beforeDestroy(){
    this.brodcast.unsubscribe('product_ch')
  }
}
</script>

<style>
</style>
