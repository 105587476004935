<template>
  <div class="fill-height py-3">
    <Loading :loading="isLoading"></Loading>
    <v-container>
      <LivePanel :liveSource="liveSource"></LivePanel>
      <v-flex class="d-flex justify-space-between mt-3">
        <h3>PRODUCTS IN LIVE</h3>
      </v-flex>
      <Divider long="100%"></Divider>
        <ProductListPanel
          :liveSource="liveSource"
          @onViewBag="$router.push({ name: 'Cart' })"
        ></ProductListPanel>
    </v-container>
  </div>
</template>

<script>
import Loading from '../../components/Loading'
import Divider from '../../components/Divider'
import LivePanel from '../../components/ShoppingLive/LivePanel'
import ProductListPanel from '../../components/ShoppingLive/ProductListPanel'
import { mapState } from 'vuex'

export default {
  name: 'Live',
  components: {
    LivePanel,
    ProductListPanel,
    Loading,
    Divider
  },
  data() {
    return {
      isLoading: false
    }
  },
  computed: {
    ...mapState({
      liveSource: (state) => state.Live.liveSource
    })
  },
  methods: {
    rernderFB() {
      this.$loadScript(
        'https://connect.facebook.net/en_GB/sdk.js#xfbml=1&version=v9.0&appId=***&autoLogAppEvents=1'
      )
        .then(() => {
          this.$nextTick(() => {
            window.FB.XFBML.parse()
          })
        })
        .catch(() => {
          console.log('facebook embed loading error')
        })
    }
  },
  async created() {
    this.isLoading = true
    await this.$store.dispatch('Live/onGetAllProductLive', this.liveSource.id)
    this.rernderFB()
    this.isLoading = false
  }
}
</script>

<style>
</style>
